@import "reset.scss";
// @import 'slick';
// @import 'slick-theme';

// 共通

// 非表示項目
.m_top_header,
.m_top_main__image,
.m_top__main__banner_wrap,
.m_top_sidemenu
{ display: none; }

body {
	font-family:   YuGothic ,sans-serif;
	line-height: 1.5em;
}

a:hover { opacity: 0.7;}


.social_modules {
    display: block;
    position: relative;
    height: auto;
    line-height: 0px;
    margin: 20px 0px;
    text-align: left;
	& .fb_iframe_widget_fluid {
	    display: inline-block;
	    margin: 5px 0;
	    top: 1px;
	}
	& .twitter-share-button {
	    position: relative !important;
	    top: -5px;
	}
	& .line-it-button {
		display: inline-block;
	    margin: 5px 0;
	}
	& .hatena-bookmark-button-frame {
		margin: 5px 0;
	}
}




// カテゴリーの色指定
.category--news {
		background: #F8E81C;
		border: 1px solid #F8E81C;
		color: #000;
		text-decoration: none;
		& a {
		color: #000;
		text-decoration: none;
		}
}

.category--articles {
		background: #A3D8DC;
		border: 1px solid #A3D8DC;
		color: #000;
		text-decoration: none;
		& a {
		color: #000;
		text-decoration: none;
		}
}

.category--investments {
		background: #A7CD4E;
		border: 1px solid  #A7CD4E;
		color: #000;
		text-decoration: none;
		& a {
		color: #000;
		text-decoration: none;
		}
}

.category--examinationstudy {
		background: #F6A623;
		border: 1px solid #F6A623;
		color: #000;
		text-decoration: none;
		& a {
		color: #000;
		text-decoration: none;
		}
}

.category--others {
		background: #76CAEC;
		border: 1px solid #76CAEC;
		color: #000;
		text-decoration: none;
		& a {
		color: #000;
		text-decoration: none;
		}
}

.category--no_color {
		background: none;
		border: 1px solid #2C446A;
		color: #2C446A;
		text-decoration: none;
		& a {
		color: #000;
		text-decoration: none;
		}
}

//タブリストの色指定
.top__main{
	&__tab_list  {
		display: block;
		position: relative;
		left: 50%;
		float: left;
		margin: 0 auto 20px ;
	}
	&__tab  {
		display: block;
		position: relative;
		left :-50%;
		margin:  0 15px 0;
		width: 100px;
		height: 30px;
		line-height: 30px;
		font-size: 1.6rem;
		float: left;
		text-align: center;
		cursor: pointer;
	}
}

.tab--all {
	background: none;
	border: 1px solid #FFF;
	color: #fff;
}
.tab--all--on {
	background: #fff;
	border: 1px solid #FFF;
	color: #2C446A;
}
.tab--news{
	background: none;
	border: 1px solid #F8E81C;
	color: #F8E81C;
}
.tab--news--on{
	background: #F8E81C;
	border: 1px solid #F8E81C;
	color: #2C446A;
}
.tab--investment {
	background: none;
	border: 1px solid #A7CD4E;
	color: #A7CD4E;
}
.tab--investment--on {
	background: #A7CD4E;
	border: 1px solid #A7CD4E;
	color: #2C446A;
}
.tab--examination {
	background: none;
	border: 1px solid #F6A623;
	color: #F6A623;
}
.tab--examination--on {
	background:  #F6A623;
	border: 1px solid #F6A623;
	color: #2C446A;
}
.tab--others {
	background: none;
	border: 1px solid #76CAEC;
	color: #76CAEC;
}
.tab--others--on {
	background: #76CAEC;
	border: 1px solid #76CAEC;
	color: #2C446A;
}


// グローバルナビ
.modal__js__black {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top:0;
	left: 0;
	padding: 20px;
	background: rgba(0, 0, 0, 0.5);
 	z-index: 1000;
 	box-sizing: border-box;
}

.modal__js__wrap {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	top:0px;
	left: 0;
	padding: 10px;
 // 	 z-index: 100;
  box-sizing: border-box;
}

.modal__js{
display: none;
position: absolute;
background-color: #EDEDED;
width: 280px;
height: auto;
padding: 15px 0px;
top: 10px;
right: 10px;
border-radius: 3px;
 z-index: 10000;
 overflow-x: hidden;
}

.modal__close__js {
	display: block;
	position: absolute;
	top: -20px;
	right: 0px;
	padding: 20px 15px 20px 20px;
	width: 19px;
	height: 19px;
	box-sizing: content-box;
	text-align: right;
	cursor: pointer;
}

.modal__menu {
	&__box {
		display: block;
		position: relative;
		width: 100%;
		padding: 15px;
		height: auto;
		border-bottom: 1px dashed #76CAEC;
		box-sizing: border-box;
		&:last-child {
		border-bottom:none;
		padding: 5px 15px 5px;
		}
		&__title {
			font-size: 1.2rem;
			font-weight: bold;
			margin: 0 0 10px 0;
		}
	}
	&__gnav {
		margin: 0;
		padding: 0;
		&__list {
			display: inline-block;
			width: 75px;
			height: 22px;
			line-height: 22px;
			margin: 0 100% 10px auto;
			padding: 0 12px;
			text-align: center;
			color: #2C446A;
			clear: both;
			font-weight: bold;
			&__sublist {
				display: block;
				font-size: 1.2rem;
				margin: 5px 0 15px 20px;
				color: #2C446A;
				font-weight: bold;
				text-decoration: none;
				& a {
					text-decoration: none;
					color: #2C446A;
				}
			}
		}
	}
	&__search {
	height: auto;
	position: relative;
	width: 100%;
	margin: 0 0 0 0;
		&__input {
			width: 80%;
			margin: 0px 0;
			padding: 5px 10px;
			height: 30px;
			border: none;
			color: #000;
			background-color: #fff;
			font-size: 1.6em;
			line-height: 30px;
			border-radius: 3px;
			box-sizing: border-box;
		}
	}
	&__tag_box {
		display: block;
		width: 100%;
		padding: 10px 0px 0px;
		box-sizing: border-box;
	}
	&__tag {
		display: block;
		font-size: 1rem;
		border: 1px solid #2C446A;
		padding: 3px 5px;
		margin: 0 5px 5px 0;
		float: left;
		border-radius: 3px;
		background: #fff;
		& a {
		text-decoration: none;
		color: #2C446A;
		}
	}
	&__follow_box {
		display: block;
		width: 100%;
		padding: 10px 0px 0px;
		box-sizing: border-box;
		&__image {
			display: block;
			margin: 0 20px 0px 0;
			float: left;
			height: 40px;
		}
	}
	&__info_box {
		display: block;
		width: 100%;
		padding: 0px 0px 0px;
		box-sizing: border-box;
	}
	&__info  {
		display: block;
		font-size: 1.2rem;
		color: #000;
		padding: 15px 0px 0px;
		font-weight: bold;
		& a {
			text-decoration: none;
			color: #000;
		}
		&--cr {
			display: block;
			font-size: 1.2rem;
			color: #000;
			padding: 15px 0px 0px;
			font-weight: bold;
			font-weight: normal;
		}
	}
}

.btn_submit {
	cursor: pointer;
	display: block;
	position: absolute;
	top:5px;
	right:0px;
	width: 20px;
	height: 20px;
	border: none;
	text-indent: -9999px;
	background: url("../../images/icon_search.svg") no-repeat 0 0;
}

.top {
	&__header {
			display: block;
			position: relative;
			width: 100%;
			background: url('../../images/top_main.png') no-repeat 0 0;
			background-size: cover;
			background-color: #76CAEC;
			padding: 30px;
			box-sizing: border-box;
			background-position: 50% 50%;
		&__logo {
			font-size: 7.3rem;
			margin: 0px auto 20px 0px;
			color: #fff;
			font-weight: bold;
		}
		&__disc {
			font-size: 2rem;
			margin: 0px  auto 50px 0px;
			color: #fff;
			font-weight: bold;
		}
		&__login_box {
			display: block;
			position: absolute;
			top: 30px;
			right: 20px;
			font-size: 1.6rem;
			color: #2C446A;
			font-weight: bold;
		}
		&__gnav {
			margin: 0;
			padding: 0;
			&__list, &__list {
				display: block;
				font-size: 2rem;
				margin: 0 0  20px 0;
				color: #2C446A;
				font-weight: bold;
				color: #2C446A;
				text-decoration: none;
				& a {
				color: #2C446A;
				text-decoration: none;
				}
				&__sublist {
					display: block;
					font-size: 1.6rem;
					margin: 0 0  20px 20px;
					color: #2C446A;
					font-weight: bold;
					color: #2C446A;
					text-decoration: none;
					& a {
					color: #2C446A;
					text-decoration: none;
					}
				}
			}
		}
	}
}



.top {
	&__main_wrap {
			display: block;
			position: relative;
			width: 100%;
			background: #2C446A;
			padding:  0 0 0 0;
			box-sizing:border-box;
	}
	&__main {
		width:930px;
		margin: 0 auto;
		box-sizing: border-box;
		overflow: hidden;
		&__banner_wrap {
		border-bottom: dashed 1px #fff;
		padding: 0 0 20px 0;
		margin: 0 0 20px 0;
		}
		&__banner {
			position: relative;
			width: 930px;
			padding: 20px 0;
			box-sizing: border-box;
			margin: 0 auto 20px;
			&__image {
				position: relative;
				float: left;
				width: 280px;
				height: 157px;
				margin: 0px 15px;
				img {
					width: 280px;
				}
			}
		}
		&__content_wrap {

		 }
		&__content_box{
		display: block;
		position: relative;
		width: 280px;
		height: 330px;
		margin: 10px 15px 20px;
		float: left;
		background: #fff;
			&__category_box {
				display: block;
				width: 100%;
				height: 48px;
				line-height: 48px;
				margin: 0;
				padding:12px;
				box-sizing: border-box;
				text-align: left;
			}
			&__category {
				display: inline-block;
				width: auto;
				height: 22px;
				line-height: 22px;
				margin: 0;
				padding: 0 12px;
				text-align: center;
				float: left;
		        & a {
				 	text-decoration: none;
		        }
			}
			&__thumbnail {
			display: block;
			position: relative;
			width: 280px;
			height: 157px;
			overflow: hidden;
				& img {
					width: 100%;
					height: auto;
					display: block;
					position: relative;
				}
			}
			&__title{
				font-size: 1.6rem;
				line-height: 1.5em;
				padding: 10px;
				box-sizing: border-box;
				height: 60px;
				overflow: hidden;
				& a {
				color: #4A4A4A;
				text-decoration: none;
				}
			}
			&__tag_box {
			    display: block;
			    position: absolute;
			    bottom: 0;
			    width: 100%;
			    height: auto;
			    padding: 10px 10px 5px;
			    box-sizing: border-box;
			}
			&__tag {
				display: block;
				font-size: 1rem;
				border: 1px solid #2C446A;
				padding: 3px 5px;
				margin: 0 5px 5px 0;
				float: left;
				border-radius: 3px;
				& a {
					text-decoration: none;
					color: #2C446A;
				}
			}
		}
		&__more_button {
		display: block;
		width: 280px;
		height: 50px;
		line-height: 50px;
		margin: 20px auto;
		font-size: 2rem;
		background: #fff;
		clear: both;
		border: none;
		}
	}
}




.contents__sidemenu {
	display: block;
	position: relative;
	float: right;
	background: none;
	margin: 0px 5px 0px 0;
}

.sidemenu {
	display: block;
	position: relative;
	float: right;
	background: none;
	margin: 10px 5px -10px 0;
	&__box--auto {
		display: block;
		position: relative;
		width: 280px;
		height: auto;
		margin: 0px 10px 30px 10px;
		float: none;
		background: #D5E5E7;
	}
	&__box {
		display: block;
		position: relative;
		width: 280px;
		height: 330px;
		margin: 0px 10px 30px 10px;
		float: none;
		background: #D5E5E7;
		&__title{
			display: block;
			width: 100%;
			height: 50px;
			line-height: 22px;
			margin: 0;
			padding:12px;
			box-sizing: border-box;
			text-align: left;
			&--keyword {
				display: inline-block;
			    width: auto;
			    height: 22px;
			    line-height: 22px;
			    margin: 0;
			    padding: 0 12px;
			    text-align: center;
				background: #2C446A;
				border: 1px solid #2C446A;
				color: #FFF;
			}
			&--ranking {
				display: inline-block;
			    width: auto;
			    height: 22px;
			    line-height: 22px;
			    margin: 0;
			    padding: 0 12px;
			    text-align: center;
				background: #D0011B;
				border: 1px solid #D0011B;
				color: #FFF;
			}
			&--recommend {
				display: inline-block;
			    width: auto;
			    height: 22px;
			    line-height: 22px;
			    margin: 0;
			    padding: 0 12px;
			    text-align: center;
				background: #FF0086;
				border: 1px solid #FF0086;
				color: #FFF;
			}

		}
		&__watch_list {
			display: none;
			position: absolute;
			top : 0px;
			right: 10px;
			font-size: 1.4rem;
		}
		&__tag_box {
		    display: block;
		    width: 100%;
		    height: auto;
		    padding: 0px 12px 7px 12px;
		    box-sizing: border-box;
		}
		&__tag {
			display: block;
			color: #2C446A;
			font-size: 1.6rem;
			border: 1px solid #2C446A;
			padding: 5px 15px;
			margin: 0 10px 10px 0;
			float: left;
			border-radius: 3px;
			background: #fff;
			& a {
				text-decoration: none;
				color: #2C446A;
			}
		}
		&__content {
			    display: block;
			    position: relative;
			    width: 100%;
			    height: 50px;
			    padding: 0 12px 0px 12px;
			    margin: 0 0 12px 0;
			    box-sizing: border-box;
			    overflow: hidden;
			&__thumbnail {
			    display: block;
			    position: relative;
			    width: 50px;
			    height: 50px;
			    margin: 0 15px 0px 0;
			    float: left;
			    overflow: hidden;
			}
			& img {
				width: 50px;
        height: 50px;
				float: left;
			}
			&__text {
				    display: block;
				    font-size: 1.4rem;
				    line-height: 1.25em;
				    overflow: hidden;
				    text-align: justify;
				& a{
					text-decoration: none;
					color: #4A4A4A;
				}
			}
		}
	}
}

.sidemenu__banner__follow--fb {
    position: relative;
    float: left;
    text-align: center;
    width: 135px;
    height: 110px;
    margin: 0px 0px 10px 0;
    padding: 15px 0 15px 0;
    background: #3B54A5;
    box-sizing: border-box;
}

.sidemenu__banner__follow--tw {
    position: relative;
    float: right;
    text-align: center;
    width: 135px;
    height: 110px;
    margin: 0px 0px 10px 0;
    padding: 15px 0 15px 0;
    background: #2BA1E5;
    box-sizing: border-box;
}


.footer {
	display: block;
	position: relative;
	width: 100%;
	background: #2C446A;
	padding:  0 0 40px 0;
	border-top: 1px solid #fff;
	box-sizing:border-box;
	clear: both;
	&__banner_wrap {
		border-top: dashed 1px #fff;
		padding: 20px 0 0px 0;
		margin: 0px 0 20px 0;
	}
	&__banner {
		position: relative;
		width: 900px;
		padding: 20px 0;
		box-sizing: border-box;
		margin: 0 auto 20px;
		&__image {
			position: relative;
			float: left;
			width: 280px;
			height: 110px;
			margin: 0px 10px;
			& img {
				width: 100%;
				height: auto;
			}
		}
		&__follow--fb {
			position: relative;
			float: left;
			text-align: center;
			width: 280px;
			height: 110px;
			margin: 0px 10px;
			padding: 15px 0 0 0;
			background: #3B54A5;
			box-sizing: border-box;
		}
		&__follow--tw {
			position: relative;
			float: left;
			text-align: center;
			width: 280px;
			height: 110px;
			margin: 0px 10px;
			padding: 15px 0 0 0;
			background: #2BA1E5;
			box-sizing: border-box;
		}
	}
}

.c__event-show__event-border-wrap {
	background-image :none;
}

.follow-title {
	font-size: 1.4rem;
	line-height: 1.6em;
	color: #FFF;
	margin: 0 0 10px 0;
}

.footer__follow__box {
    display: block;
    position: relative;
    width: 100%;
    height: 80px;
    line-height: 57px;
    margin: 0 0 20px 0;
    padding: 0 10px;
    border-bottom: 1px solid #bbb;
    color: #888;
    background: #F0E8DE;
    text-align: center;
    box-sizing: border-box;
}

.footer__box {
  display: block;
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  height: auto;
  padding: 0px 15px 0px;
  box-sizing: border-box;
}

.footer__copy{
  display: block;
  font-size: 1.2rem;
  color: #fff;
  padding: 15px 0px 0px;
  font-style: normal;
}

.footer__menu {
  display: block;
  position: relative;
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  height: auto;
}

.footer__menu__list {
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
  margin: 15px 15px 0px 0;
  line-height: 1em;
  color: #fff;
  text-decoration: none;
  a {
  color: #fff;
  text-decoration: none;
  }
}


.footer__powered{
  display: block;
  font-size: 1.2rem;
  color: #fff;
  padding: 15px 0px 15px;
}

// サブページ

.sub_header  {
  transform: translate3d(0, 0, 0);
  display: block;
	position: relative;
	 height: 80px;
	 width: 100%;
	 background: #76CAEC;
	&__logo {
		display: block;
		position: absolute;
		top: 24px;
		left:24px;
	}
	&__disc {
		display: block;
		position: absolute;
		top: 32px;
		left: 220px;
		line-height: 1.5em;
	}
	&__menu_link {
		display: block;
		position: absolute;
		top:20px;
		right:20px;
		width: 30px;
		height: 30px;
		text-align: right;
		cursor: pointer;
		& img {
			width: 30px;
			height: 30px;
		}
	}
	&__serch {
		display: block;
		position: absolute;
		top:20px;
		right: 70px;
		width: 30px;
		height: 30px;
		text-align: right;
		cursor: pointer;
		& img {
			width: 30px;
			height: 30px;
		}
	}
}

.main__wrap {
	display: block;
	position: relative;
	overflow: hidden;
	width: 100%;
	padding: 10px 0 10px 0 ;
	background: #F0FAFA;
}

 body.unlogin .main__wrap {
	background: #2C446A;
}


.main {
	 display: block;
	 width: 930px;
	 margin: 0 auto;
}

.contents__header{
	display: block;
	position: relative;
	height: auto;
	width: 100%;
	padding: 0 15px;
	box-sizing: border-box;
	&__breadcrumb {
		margin:6px 0px 20px -10px;
		& li {
			display: inline;
			list-style-type: none;
			font-size: 1.2rem;
			color: #2C446A;
			padding: 0 15px 0 10px;
			background:  url('../../images/breadcrumb.svg') no-repeat;
			background-position: 100% 50%;
		}
		& li:last-child{
			background:  none;
		}
		& a {
			color: #2C446A;
/* 			text-decoration: none; */
		}
	}
	&__category_box {
		display: block;
		width: 100%;
		margin: 0 0 18px 0;
		padding:0px 0 0px 0;
		box-sizing: border-box;
		text-align: left;
	}
	&__category {
		display: inline-block;
		width: auto;
		height: 32px;
		line-height: 32px;
		margin: 0;
		padding: 0 18px;
		text-align: center;
		font-size: 1.6rem;
		font-weight: bold;
	    &  a {
	        text-decoration: none;
		}
	}
	&__date {
		display: block;
		font-size: 1.2rem;
		margin: 0px 0 12px 0;
		color: #2C446A;
	}
	&__title {
		color: #2C446A;
		font-size: 3.2rem;
		margin: 0px 0 14px 0;
		line-height: 1.5em;
	}
	&__tag_box {
		display: block;
		width: 100%;
		height: auto;
		padding:  0px 0px  10px 0px;
		box-sizing: border-box;
	}
	&__tag {
		display: block;
		color: #2C446A;
		font-size: 1.6rem;
		border: 1px solid #2C446A;
		padding: 5px 15px;
		margin: 0 10px 10px 0;
		float: left;
		border-radius: 3px;
		background: #fff;
		& a {
			text-decoration: none;
			color: #2C446A;
		}
	}
}

.contents__main {
	display: block;
	width: 590px;
	float: left;
	margin: 0px 0px 20px 15px;
	padding: 0px 20px;
	background: #fff;
	box-sizing: border-box;
	font-size: 1.7rem;
	line-height:  1.8em;
	text-align: justify;
	color: #2C446A;
	& h2,h3 {
		font-size: 2.2rem;
		line-height:  1.8em;
		margin: 1em 0 0.2em 0;
		color: #2C446A;
	}
	& p {
		font-size: 1.7rem;
		line-height:  1.8em;
		text-align: left;
		margin: 0 0 1em 0;
		color: #2C446A;
	}
	& img {
		max-width: 100%;
		height: auto;
	}
}

.contents__main__1column {
	display: block;
	width: 900px;
	float: left;
	margin: 0px 0px 20px 15px;
	padding: 0px 100px;
	background: #fff;
	box-sizing: border-box;
	font-size: 1.7rem;
	line-height:  1.8em;
	text-align: justify;
	color: #2C446A;
	& h2,h3 {
font-size: 2.2rem;
line-height: 1.8em;
margin: 1em 0 20px 0;
padding: 0 0 5px 0px;
color: #2C446A;
text-align: center;
border-bottom: 1px solid #ccc;
	}
	& p {
		font-size: 1.7rem;
		line-height:  1.8em;
		text-align: left;
		margin: 0 0 1em 0;
		color: #2C446A;
	}
	& img {
		max-width: 100%;
		height: auto;
	}
}


.contents__main__image {
	display: block;
	width: 900px;
	max-height: 518px;
	overflow: hidden;
	margin: 0 15px 30px 15px;
	& img {
		width: 100%;
		height: auto;
	}
}

.contents__main__image--works {
    display: block;
    width: 900px;
    height: 506px;
    text-align: center;
    overflow: hidden;
    background: #D4E5E6;
    margin: 0 15px 30px 15px;
    box-sizing: border-box;
    padding: 30px;
	& img {
	    width: auto;
	    height: 100%;
	}
}

.contents__footer {
    display: block;
    width: 590px;
    float: left;
    margin: 0px 0px 20px 0;
    padding: 0px 20px;
    background: none;
    box-sizing: border-box;
}

// カテゴリー

.category {
	&__breadcrumb__wrap {
		display: block;
		width: 100%;
		height: 39px;
		line-height: 27px;
		border-bottom: dashed 1px #2C446A;
		padding: 0px 0 0px 0;
		margin: 0px 0 20px 0px;
	}
	&__breadcrumb {
		max-width: 900px;
		position:relative;
		margin: 0px auto 0px auto;
		left: -10px;
		& li {
			display: inline;
			list-style-type: none;
			font-size: 1.2rem;
			color: #2C446A;
			padding: 0 15px 0 10px;
			background:  url('../../images/breadcrumb.svg') no-repeat;
			background-position: 100% 50%;
		}
		& li:last-child{
			background:  none;
		}
		& a {
			color: #2C446A;
	/* 		text-decoration: none; */
		}
	}
}

.category {
	&__title {
		color: #2C446A;
		font-size: 3.2rem;
		margin: 0px 0 20px 0;
		line-height: 1.5em;
		text-align: center;
	}
	&__title--new {
		color: #2C446A;
		font-size: 2.4rem;
		margin: 0px 0 0px 0;
		line-height: 1.5em;
		text-align: center;
	}
	&__main {
		&__image {
			display: block;
			position: relative;
			width: 900px;
			height: 506px;
			overflow: hidden;
			margin: 0 15px 20px 15px;
			background: #fff;
			& img {
				width: 100%;
				height: auto;
			}
		}
		&__disc_box {
			display: block;
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 70px;
			margin: -70px 0 0 0;
			background: rgba(0,0,0,0.50);
			z-index: 10;
			padding: 15px;
			box-sizing: border-box;
		}
		&__disc {
			font-size: 1.4rem;
			display: block;
			color: #fff;
			z-index: 20;
			line-height: 1.5em;
		}
	}
}

.category {
	&__content_box{
	display: block;
	position: relative;
	width: 280px;
	height: 330px;
	margin: 15px 15px;
	float: left;
	background: #fff;
		&__category_box {
			display: block;
			width: 100%;
			height: 48px;
			line-height: 48px;
			margin: 0;
			padding: 12px;
			box-sizing: border-box;
			text-align: left;
		}
		&__category {
			display: inline-block;
			width: auto;
			height: 22px;
			line-height: 22px;
			margin: 0;
			padding: 0 12px;
			text-align: center;
			float: left;
	        & a {
	          text-decoration: none;
	        }
		}
		&__thumbnail {
		display: block;
		position: relative;
		width: 280px;
		height: 157px;
		overflow: hidden;
			& img {
				display: block;
				position: relative;
				width: 100%;
				height: auto;
			}
		}
		&__title{
			font-size: 1.6rem;
			line-height: 1.5em;
			padding: 10px;
			box-sizing: border-box;
			height: 60px;
			overflow: hidden;
			& a {
			color: #4A4A4A;
			text-decoration: none;
			}
		}
		&__tag_box {
		    display: block;
		    position: absolute;
		    bottom: 0;
		    width: 100%;
		    height: auto;
		    padding: 10px 10px 5px;
		    box-sizing: border-box;
		}
		&__tag {
			display: block;
			font-size: 1rem;
			border: 1px solid #2C446A;
			padding: 3px 5px;
			margin: 0 5px 5px 0;
			float: left;
			border-radius: 3px;
			& a {
				text-decoration: none;
				color: #2C446A;
			}
		}
	}

}

// 関連コンテンツ｜青

.relation_contents {
	&__wrap {
		display: block;
		background: #BFD9E1;
		padding: 20px 0 20px 0;
	}
	&__main {
		display: block;
		width: 590px;
		float: left;
	}
	&__title {
		color: #2C446A;
		font-size: 2.2rem;
		margin: 5px 0 10px 0;
		line-height: 1.5em;
		text-align: center;
		font-weight: bold;
	}
}

.relation_contents {
	&__content_box{
	display: block;
	position: relative;
	width: 280px;
	height: 330px;
	margin: 15px 15px;
	float: left;
	background: #fff;
		&__category_box {
			display: block;
			width: 100%;
			height: 48px;
			line-height: 48px;
			margin: 0;
			padding: 12px;
			box-sizing: border-box;
			text-align: left;
		}
		&__category {
			display: inline-block;
			width: auto;
			height: 22px;
			line-height: 22px;
			margin: 0;
			padding: 0 12px;
			text-align: center;
			float: left;
	        & a {
	          text-decoration: none;
	        }
		}
		&__thumbnail {
		display: block;
		position: relative;
		width: 280px;
		height: 157px;
		overflow: hidden;
			& img {
				display: block;
				position: relative;
				width: 100%;
				height: auto;
			}
		}
		&__title{
			font-size: 1.6rem;
			line-height: 1.5em;
			padding: 10px;
			box-sizing: border-box;
			height: auto;
			overflow: hidden;
			& a {
			color: #4A4A4A;
			text-decoration: none;
			}
		}
		&__tag_box {
		    display: block;
		    position: absolute;
		    bottom: 0;
		    width: 100%;
		    height: auto;
		    padding: 10px 10px 5px;
		    box-sizing: border-box;
		}
		&__tag {
			display: block;
			font-size: 1rem;
			border: 1px solid #2C446A;
			padding: 3px 5px;
			margin: 0 5px 5px 0;
			float: left;
			border-radius: 3px;
			& a {
				text-decoration: none;
				color: #2C446A;
			}
		}
	}

}

.relation_contents__sidemenu {
	display: block;
	position: relative;
	float: right;
	background: none;
	margin: 68px 0px 0px 0;
	}


// シリーズコンテンツ｜藍

.series_contents {
	&__wrap {
		display: block;
		background: #2C446A;
		padding: 20px 0 20px 0;
	}
	&__main {
		display: block;
		background: #2C446A;
		width: 590px;
		margin: 0 0 0 15px;
		float: left;
	}
	&__title {
		color: #FFF;
		font-size: 3.2rem;
		margin: 0px 0 20px 0;
		line-height: 1.5em;
		text-align: center;
	}
}

.series_contents {
	&__content_box{
	display: block;
	position: relative;
	width: 590px;
	height: 170px;
	margin: 0px 0px 20px 0px;
	float: none;
	background: #fff;
		&__category_box {
			display: block;
			width: 100%;
			height: 48px;
			line-height: 48px;
			margin: 0;
			padding: 20px 0 0 20px;
			box-sizing: border-box;
			text-align: left;
		}
		&__category {
			display: block;
			width: auto;
			height: 22px;
			line-height: 22px;
			margin: 0;
			padding: 0 12px;
			text-align: center;
			float: left;
		}
		&__thumbnail {
				display: block;
				position: absolute;
				top: 20px;
				right: 20px;
				width: 240px;
				height: 135px;
				float: right;
				overflow: hidden;
				& img {
					height: auto;
					width: 100%;
				}
		}
		&__title{
			font-size: 1.8rem;
			line-height: 1.5em;
			padding: 10px 0 20px 20px;
			box-sizing: border-box;
			height: 60px;
			overflow: hidden;
			& a {
			color: #4A4A4A;
			text-decoration: none;
			}
		}
		&__tag_box {
		    display: block;
		    position: absolute;
		    bottom: 0;
		    width: 100%;
		    height: auto;
		    padding: 10px 10px 5px;
		    box-sizing: border-box;
		}
		&__tag {
			display: block;
			font-size: 1rem;
			border: 1px solid #2C446A;
			padding: 3px 5px;
			margin: 0 5px 5px 0;
			float: left;
			border-radius: 3px;
			& a {
				text-decoration: none;
				color: #2C446A;
			}
		}
		&__disc {
			display: block;
			width: 340px;
			height: auto;
			padding: 0px 20px 0 20px;
			line-height: 1.5em;
			box-sizing: border-box;
			font-size: 1.4rem;
		}
	}
}

.series_contents__sidemenu {
	display: block;
	position: relative;
	float: right;
	background: none;
	margin: 68px 5px 0px 0;
	}

/* サブカテゴリー */

.sub_category__main__wrap {
	display: block;
	width: 930px;
	margin: 0 auto;
}

.sub_category__main {
	display: block;
	width: 620px;
	margin: 0 auto;
	float: left;
}

.sub_category__more_button {
	display: block;
	position: relative;
	top:20px;
	width: 280px;
	height: 50px;
	line-height: 50px;
	margin: 20px auto;
	font-size: 2rem;
	background: #fff;
	clear: both;
	border: none;
}

.sub_category__sidemenu {
	display: block;
	position: relative;
	float: right;
	background: none;
	margin: 15px 5px 0px 0;
	}

/* 固定ページ */


/* 作品紹介*/

.works__main__wrap {
	display: block;
	width: 930px;
	margin: 0 auto;
}

.works__main {
	display: block;
	width: 930px;
	margin: 0 auto;
}

.book_date {
    display: block;
    position: relative;
    background: #EDEDED;
    width: 100%;
    margin: 20px 0px 20px 0px;
    padding: 20px;
    line-height: 2em;
    box-sizing: border-box;
	& dt {
		display: inline-block;
		width: 20%;
		float: left;
		font-size: 1.4rem;
		color: #2C446A;
	}
	& dd {
		display: inline-block;
		width: 80%;
		font-size: 1.4rem;
		color: #2C446A;
	}
}

.works_series_contents {
	&__content_box{
	    display: block;
	    position: relative;
	    width: 900px;
	    height: 170px;
	    margin: 0px 15px 30px 15px;
	    float: none;
	    background: #fff;
		&__category_box {
		    display: block;
		    width: 100%;
		    height: 48px;
		    line-height: 48px;
		    margin: 0;
		    padding: 20px 0 0 20px;
		    box-sizing: border-box;
		    text-align: left;
		}
		&__category {
			display: block;
			width: auto;
			height: 22px;
			line-height: 22px;
			margin: 0;
			padding: 0 12px;
			text-align: center;
			float: left;
		}
		&__thumbnail {
			    display: block;
			    position: absolute;
			    text-align: center;
			    top: 20px;
			    right: 20px;
			    width: 240px;
			    height: 135px;
			    float: right;
			    overflow: hidden;
			    background: #D5E5E7;
			    padding: 10px;
			    box-sizing: border-box;
				& img {
					height: 115px;
					width: auto;
				}
		}
		&__title{
		    font-size: 1.8rem;
		    line-height: 1.5em;
		    padding: 5px 0 0px 20px;
		    box-sizing: border-box;
		    height: auto;
		    overflow: hidden;
			& a {
			color: #4A4A4A;
			text-decoration: none;
			}
		}
		&__tag_box {
		    display: block;
		    position: absolute;
		    bottom: 0;
		    width: 100%;
		    height: auto;
		    padding: 10px 10px 5px;
			box-sizing: border-box;
		}
		&__tag {
		    display: block;
		    font-size: 1rem;
		    border: 1px solid #2C446A;
		    padding: 3px 5px;
		    margin: 0 5px 5px 0;
		    float: left;
		    border-radius: 3px;
			& a {
				text-decoration: none;
				color: #2C446A;
			}
		}
		&__disc {
		    display: block;
		    width: 640px;
		    height: auto;
			line-height: 1.5em;
		    padding: 5px 20px 0 20px;
		    box-sizing: border-box;
		    font-size: 1.4rem;
		}
	}
}

// 作品紹介サイドメニュー

.works {
	&__sidemenu {
	display: block;
	position: relative;
	float: right;
	background: none;
	margin: 10px 5px -10px 0;
		&__box {
			display: block;
			position: relative;
			width: 280px;
			height: auto;
			margin: 0px 10px 30px 10px;
			float: none;
			background: #fff;
		}
	}
	&__content_box{
	display: block;
	position: relative;
	width: 280px;
	height: 330px;
	margin: 15px 15px;
	float: left;
	background: #fff;
		&__category_box {
			display: block;
			width: 100%;
			height: 48px;
			line-height: 48px;
			margin: 0;
			padding: 12px;
			box-sizing: border-box;
			text-align: left;
		}
		&__category {
			display: inline-block;
			width: auto;
			height: 22px;
			line-height: 22px;
			margin: 0;
			padding: 0 12px;
			text-align: center;
			float: left;
	        & a {
	          text-decoration: none;
	        }
		}
		&__thumbnail {
		display: block;
		position: relative;
		width: 280px;
		height: 157px;
		overflow: hidden;
		padding: 10px;
		text-align: center;
		box-sizing: border-box;
	    background: #D4E5E6;
			& img {
				display: block;
				position: relative;
				width: auto;
				height: 100%;
				margin: 0 auto;
			}
		}
		&__title{
			font-size: 1.6rem;
			line-height: 1.5em;
			padding: 10px;
			box-sizing: border-box;
			height: 60px;
			overflow: hidden;
			& a {
			color: #4A4A4A;
			text-decoration: none;
			}
		}
		&__tag_box {
			display: block;
			width: 100%;
			height: 70px;
			padding: 15px 15px;
			box-sizing: border-box;
		}
		&__tag {
			display: block;
			font-size: 1rem;
			border: 1px solid #2C446A;
			padding: 3px 5px;
			margin: 0 5px 5px 0;
			float: left;
			border-radius: 3px;
			& a {
				text-decoration: none;
				color: #2C446A;
			}
		}
		&__disc {
			display: block;
			width: 340px;
			height: auto;
			line-height: 1.5em;
			padding: 0px 10px 20px 10px;
			box-sizing: border-box;
			font-size: 1.2rem;
		}
	}

}

.box_404 {
	width: 100%;
	padding: 20px;
	max-width: 390px;
	margin: 0 auto;
	box-sizing: border-box;
	& img {
		width : 100%;
		margin: 0px 0 10px 0;
	}
	&__back {
		text-align: center;
		width : 200px;
		margin: 0 auto 0px;
		& img {
		width : 100%;
		margin: 0px 0 5px 0;
		}
		& a {
			font-size: 1.4rem;
			color: #2C446A;
		}
	}
}

// コンテンツ内テンプレート
.book_list {
    display: block;
    border: 1px solid #979797;
    margin:  0 0 20px 0;
    padding: 0;
}

.book_list__image {
	display: block;
    width: 100%;
    height: 310px;
    text-align: center;
    overflow: hidden;
    background: #D4E5E6;
    margin: 0 0px 20px 0px;
    box-sizing: border-box;
    padding: 20px;
    & img {
	width: auto;
    height: 100%;
    }
}

.book_list__title {
	display: block;
    margin: 0 0 10px 20px;
    font-size: 2.2rem;
    font-weight: bold;
}

.book_list {
	&__read {
		display: block;
		width: 100%;
		height: 50px;
		padding: 0px 20px 15px;
		box-sizing: border-box;
	}
	&__read li a{
		display: block;
		font-size: 1.4rem;
		background: #F7F7BF;
		border: 1px solid #F6A623;
		padding: 0px 15px;
		margin: 0 20px 5px 0;
		float: left;
		border-radius: 3px;
		text-decoration: none;
		color: #2C446A;
		font-weight: bold;
	}
}

//アニメーションの調整
.modal__js {
  animation-duration: 0.25s;
  -vendor-animation-iteration-count: infinite;
}

.modal__js__black {
  animation-duration: 0.25s;
  -vendor-animation-iteration-count: infinite;
}

.main__wrap__is_preview {
  font-size: 1.2rem;
  line-height: 45px;
  height: 45px;
  background: #2C446A;
  color: #FFF;
  text-align: center;
}

// 料金表
 .c__top-page{
    &__bottom-member{
      &__box{
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
      }
      &__inbox-wrap{
        margin: 0 auto;
        width: 100%;
        max-width: 730px;
        padding: 10px 20px;
        box-sizing: border-box;
      }
      &__right-inbox{
        margin-top: 20px;
        float: none;
        text-align: center;
        color: #00688E;
        & p {
	        text-align: center;
        }
      }
      &__left-inbox{
        float: left;
        text-align: left;
        color: #4A4A4A;
      }
      &__title{
        font-size: 2rem;
        line-height: 20px;
        margin-bottom: 10px;
      }
      &__subtitle{
        font-size: 1.6rem;
        line-height: 35px;
      }
      &__detail{
        font-size: 1.2rem;
        line-height: 20px;
        &__list{
          font-size: 1.2rem;
          margin-top: 15px;
          line-height: 20px;
        }
      }
      &__price{
        font-size: 7.5rem;
        font-family: Helvetica-Light;
      }
    }
  }


.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #fff url(../../images/ajax-loader.gif) center center no-repeat;
}

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url(../../assets/slick/slick.eot);
  src: url(../../assets/slick/slick.eot) format("embedded-opentype"), url(../../assets/slick/slick.woff) format("woff"), url(../../assets/slick/slick.ttf) format("truetype"), url(/assets/slick-8ca11b5fcbff92ee6378017cf6832dddfa745253202d7c8d01f42d5d17c4cb5c.svg#slick) format("svg");
}

.slick-prev.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: '←';
}

[dir='rtl'] .slick-prev:before {
  content: '→';
}

.slick-next {
  right: -25px;
}

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: '→';
}

[dir='rtl'] .slick-next:before {
  content: '←';
}

.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -45px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black;
}

.realz-wrapper {
  width: 100vw;
  margin: 0 auto;
  text-align: center;
}

.realz-wrapper img {
  height: 60vh;
  margin: 50px 0 20px;
}

.realz-commingsoon {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 100px;
  line-height: 1.4em;
}

@media screen and (max-width: 420px) {
  .realz-wrapper {
    height: 80vh;
  }

  .realz-wrapper img {
    width: 40vh;
  }
  
  .realz-commingsoon {
    font-size: 2em;
    width: 90vw;
    margin: 0 auto;
  }
}

